import React from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
/* import { Redirect } from 'react-router'; */
import LoginForm from './loginForm';
import DashBoard from './dashBoard';
import Register from './register';
import Recognition from './recognition';
import Notification from './notification';
import UserSettings from './userSettings';

const MainRouter = () => {
	return (
		<>
			<BrowserRouter>
				<HashRouter basename='/'>
					<Switch>
						<Route exact path='/' component={LoginForm} />
						<Route exact path='/dashboard' component={DashBoard} />
						{/* <Route exact path='/'>
						{loggedIn ? <Redirect to='/dashboard' /> : <LoginForm />}
					</Route> */}
						<Route exact path='/register' component={Register} />
						<Route exact path='/recognition' component={Recognition} />
						<Route exact path='/notification' component={Notification} />
						<Route exact path='/usersettings' component={UserSettings} />
					</Switch>
				</HashRouter>
			</BrowserRouter>
		</>
	);
};
export default MainRouter;
