import React from 'react';
import Storetraffic from './storeTraffic/storetraffic';
import Header from './header';
import Navigation from './navigation';

import './dashboard.css';

/* import Emotional from "./emotional"; */

function DashBoard() {
	return (
		<div className='dashboard-container'>
			<p className='text'>Cyrix Experience Platform</p>
			<Header heading='Store traffic' />
			<section className='flex'>
				<Navigation />
				<Storetraffic />
			</section>

			{/* <Emotional /> */}
		</div>
	);
}

export default DashBoard;
