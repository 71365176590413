import React from 'react';
import PersonDetails from './personDetails';
import RecentExperience from './recentExperience';
import EmotionChart from './storeTraffic/emotionChart';
import Header from './header';
import Navigation from './navigation';
import './recognition.css';

function Recognition() {
	return (
		<div className='matching-container'>
			<p className='text'>Cyrix Experience Platform</p>
			<Header heading='Profile matching' />
			<section className='flex'>
				<Navigation />

				<section className='recognition'>
					<div className='row'>
						{/* personal RecentExpertience section */}
						<div className='col-md-6'><PersonDetails /></div>
						<div className='col-md-6'><RecentExperience /></div>
						<div className='col-md-12'><EmotionChart /></div>
					</div>
				</section>
			</section>
		</div>
	);
}
export default Recognition;
