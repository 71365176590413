import React from 'react';
import Rodal from 'rodal';
// include styles
import 'rodal/lib/rodal.css';
import GenderTimeChart from './genderTime';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Loader from './../../loader';

import './../age/ageRodal.css';

import { format } from 'date-fns';
/* import configStore from '../../../redux/store'; */

/* const { store } = configStore(); */

/* import "./gender.css"; */
require('highcharts/modules/exporting')(Highcharts);
/* require('highcharts/modules/export-data')(Highcharts); */

class Gender extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			visible: false,
			IsModalOn: false,
			series_data: [
				['Male', 0],
				['Female', 0],
			],
			genderData: [],
		};
		this.allowChartUpdate = true;
		// this.range = JSON.parse(store.getState().slider.range)
		this.getGenderDist();
	}

	getGenderDist() {
		this.setState({ loading: true });
		/* 	console.log("STORE RANGE ", store.getState())
		console.log("STORE RANGE ", store.getState().slider.range) */
		// this.range = JSON.parse(store.getState().slider.range);
		// console.log("THIS.RANGE ", this.range)
		// const frm = format(new Date(JSON.parse(store.getState().slider.range)[0]), 'yyyy-MM-dd');
		// const to = format(new Date(JSON.parse(store.getState().slider.range)[1]), 'yyyy-MM-dd')
		const genderURL =
			'https://api.cyrix.io:8443/facade/rest/genderDistribution/filter/';
		const options = {
			method: 'POST',
			mode: 'cors',
			headers: new Headers({
				'Content-Type': 'application/json',
				// 'Authorization' : 'Bearer '+ store.getState().login.token,
				Authorization: 'Bearer ' + sessionStorage.getItem('authToken'),
			}),
			body: JSON.stringify({
				site: 1,
				from: format(
					new Date(JSON.parse(sessionStorage.getItem('range'))[0]),
					'yyyy-MM-dd'
				),
				to: format(
					new Date(JSON.parse(sessionStorage.getItem('range'))[1]),
					'yyyy-MM-dd'
				),
			}),
		};

		fetch(genderURL, options)
			.then((data) => data.json())
			.then((data) => {
				var male = 0;
				var female = 0;
				var formattedData = [];
				for (var i = 0; i < data.length; i++) {
					male += data[i].males;
					female += data[i].females;
					var elm = [
						new Date(data[i].date).getTime(),
						data[i].males + data[i].females,
					];
					formattedData.push(elm);
				}
				this.setState((prevState) => ({
					series_data: [
						['Male', male],
						['Female', female],
					],
					// genderData: [...prevState.genderData, formattedData],
					genderData: formattedData,
					loading: false,
				}));

				console.log('GENDER ', this.state);
			})
			.catch((error) => {
				console.log('ERROR ', error);
			});
	}

	show() {
		this.setState({ IsModalOn: true, visible: true });
	}

	hide() {
		this.setState({ IsModalOn: false, visible: false });
	}
	/* const componentStyle = {
		margin: "4px",
		minWidth: "30%"
	}; */

	render() {
		/* const ReactHighcharts = require("react-highcharts"); */
		this.options = {
			chart: {
				type: 'pie',
				margin: [0, 0, 0, 15],
				spacingTop: 0,
				spacingBottom: 0,
				spacingLeft: 0,
				spacingRight: 0,

				// Explicitly tell the width and height of a chart
				height: 175,
				// height: (1.3 / 3) * 100 + "%",
				// width: 400
				// height: 175
				events: {
					load: function (event) {
						var chart = this,
							points = chart.series[0].points,
							len = points.length,
							total = 0,
							i = 0;

						for (; i < len; i++) {
							total += points[i].y;
						}

						chart.setSubtitle({
							text: 'Total:' + total,
							align: 'center',
							verticalAlign: 'middle',
							y: 1,
							x: 5,
							style: {
								fontWeight: 'bold',
								fontSize: '12',
							},
						});
					},
				},
			},
			title: {
				useHTML: true,
				text: '<div>Gender Distribution</div>',
				align: 'left',
				/* y: 4,
				x: 0, */
				style: {
					color: 'rgb(16, 16, 122)',
					font:
						'bold 16px "Nunito" Arial, Helvetica sans-serif' /* ,
					"border-bottom": ".5px solid black" */,
				},
			},
			credits: {
				enabled: false,
			},
			plotOptions: {
				pie: {
					size: '50%',
					innerSize: '80%',
					depth: '90%',
					dataLabels: {
						enabled: true,
						format: '<b>{point.name}</b>:{point.percentage:.1f} %',
						/* format:
							'<br><b>{point.name}</b>:{point.percentage:.1f} %<br>total: {point.total}' */
					},
				},
			},
			series: [
				{
					name: 'Gender',
					data: this.state.series_data,
				},
			],
			responsive: {
				rules: {
					condition: {
						minWidth: 100,
						callback() {
							return true;
						},
					},
					chartOptions: {
						legend: { enabled: false },
						yAxis: { title: { text: '' } },
					},
				},
			},
		};
		return (
			<div>
				<div className='gender' onClick={this.show.bind(this)}>
					{this.state.loading ? (
						<Loader />
					) : (
						<HighchartsReact
							allowChartUpdate={this.allowChartUpdate}
							highcharts={Highcharts}
							options={this.options}
						/>
					)}
				</div>

				{this.state.IsModalOn ? (
					<Rodal
						customStyles={{
							height: '60%',
							width: '50%',
							bottom: 'auto' /* ,
						top: '20%' */,
						}}
						visible={this.state.visible}
						onClose={this.hide.bind(this)}
					>
						<div style={{ width: '95%', textAlign: 'center' }}>
							<GenderTimeChart genderData={this.state.genderData} />
						</div>
					</Rodal>
				) : (
					''
				)}
			</div>
		);
	}
}
export default Gender;
