import React from "react";
//import dashboard from "./../img/dashboardwhite.png";
//import Recognition from "./../img/recognition.png";
//import mapping from "./../img/mapping.png";
//import notification from "./../img/notification.png";
//import support from "./../img/headphones.png";
//import information from "./../img/information.png";
//import userSettings from "./../img/userSettings.png";
import { Link } from "react-router-dom";

import "./navigation.css";

function Navigation() {
	return (
		<>
			<section className='nav'>
				<ul className='uList-nav'>
					<li className='list-nav'>
						<Link to='/Dashboard' className='linkDecoration'>
								<span className='icon-traffic'></span>
								<span>Store Traffic</span>
						</Link>
					</li>
					<li className='list-nav'>
						<Link to='#' className='linkDecoration disable'>							
								<span className='icon-recognition'></span>
								<span>Profile Matching</span>
						</Link>
					</li>
					<li className='list-nav'>
						<Link to="#" className='linkDecoration disable' >					
								<span className='icon-mapping'></span>
								<span>Experience Mapping</span>
						</Link>
					</li>
					<li className='list-nav'>
						<Link to='/notification' className='linkDecoration'>						
								<span className='icon-notification'></span>
								<span>Notification Center</span>
						</Link>
					</li>
					<li className='list-nav'>
						<Link to="" className='linkDecoration disable'>
								<span className='icon-support'></span>
								<span>Support</span>
						</Link>
					</li>
					<li className='list-nav'>
						<Link to="" className='linkDecoration disable'>
						<span className='icon-faq'></span>
								<span>FAQ</span>
						</Link>
					</li>
				</ul>
				<ul className='uList-nav'>
					<li className='list-nav'>
						<Link to='/usersettings' className='linkDecoration disable'>
						<span className='icon-user-settings'></span>
							<span> User Settings</span>
						</Link>
					</li>
				</ul>
			</section>
		</>
	);
}
export default Navigation;
