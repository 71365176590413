import { createStore } from 'redux';
import rootReducer from './reducers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';



const persistConfig = {
	key: 'root',
	storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
	let store = createStore(persistedReducer)
	let persistor = persistStore(store)

	store.subscribe(() => {
		console.log('STORE CHANGED ', store.getState())
	})

	return { store, persistor }
}
