import { ADD_AUTH_TOKEN } from '../actionTypes';


const init_state = {
	token : ""
}

export default function( state = init_state, action){
	if(action.type === ADD_AUTH_TOKEN){
		return {
			...state, 
			token: action.payload.token
		}
	}
	else{
		return state;
	}
}