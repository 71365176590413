import React from "react";
/* import { Form } from 'react-bootstrap'; */
import { NavLink } from "react-router-dom";
import line from "./../img/Linelogin.png";
import logo from "./../img/loginlogo.png";
import "./register.css";

function Register() {
	return (
		<div className='create-account-container'>
			<div className='flex center-create'>
				<img src={logo} alt='CyrixLogo' />
				<h2>Cyrix Experience Platform</h2>
			</div>

			<form className='form-create-account'>
				<p className='create-account-p'>Create account for Cyrix Platform</p>
				{/* 	<p className='text-muted'>Cyrix Experience Platform</p> */}
				<div className='inputGroup-create'>
					<input type='text' placeholder='Name' />

					<input type='email' placeholder='Login' />

					<input type='password' placeholder='Password' />
					<ul className='create-account-list'>
						<li>Minimum 8 Charecters</li>
						<li>One specialCharecter</li>
						<li>4 Unique Charecters</li>
					</ul>
					<NavLink to='/'>
						<button className='submit-create'>Create account</button>
					</NavLink>
					<img src={line} alt='line' className='imgLine' />
					<p>
						Already have account? <NavLink to='/'>login here</NavLink>
					</p>
				</div>
			</form>
		</div>
	);
}
export default Register;
