import React from 'react';
import Highcharts from 'highcharts';
import Rodal from 'rodal';
import './ageRodal.css';

import { format } from 'date-fns';
import configStore from '../../../redux/store';
import Loader from './../../loader';

// include styles
import 'rodal/lib/rodal.css';
import AgeTimeChart from './ageTime';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

/* const { store } = configStore(); */

class Age extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			visible: false,
			IsModalOn: false,
			series_data: [],
			age_data: {
				fifteen: [],
				twenty: [],
				twenty_five: [],
				thirty: [],
				thirty_five: [],
				forty: [],
				forty_five: [],
				fifty: [],
				fifty_five: [],
				sixty: [],
				sixty_five: [],
				seventy: [],
				seventy_five: [],
				eighty: [],
				eighty_five: [],
				ninety: [],
				ninety_five: [],
			},
		};
		// this.range = JSON.parse(store.getState().slider.range);
		this.getAgeDistribution();
	}

	getAgeDistribution() {
		this.setState({ loading: true });
		// console.log("STORE RECIEVED ", store.getState());
		// const token = store.getState().login.token;
		const token = sessionStorage.getItem('authToken');
		// this.range = JSON.parse(store.getState().slider.range);
		this.range = JSON.parse(sessionStorage.getItem('range'));
		const ageURL =
			'https://api.cyrix.io:8443/facade/rest/ageDistribution/filter/';
		const options = {
			method: 'POST',
			mode: 'cors',
			headers: new Headers({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			}),
			body: JSON.stringify({
				site: 1,
				// from: format(new Date("2019-01-01"), "yyyy-MM-dd"),
				from: format(new Date(this.range[0]), 'yyyy-MM-dd'),
				to: format(new Date(this.range[1]), 'yyyy-MM-dd'),
			}),
		};

		fetch(ageURL, options)
			.then((data) => data.json())
			.then((data) => {
				var series_data = [];
				var self = this;
				for (const elem of data) {
					Object.keys(elem.distribution).forEach((cat) => {
						// age distribution
						if (
							series_data[cat] === undefined ||
							series_data[cat]['y'] === undefined
						) {
							series_data[cat] = {
								y: elem.distribution[cat],
								color: ('#' + Math.floor(Math.random() * 10000000 + 1)).slice(
									0,
									7
								),
							};
						} else {
							series_data[cat] = {
								y: series_data[cat]['y'] + elem.distribution[cat],
								color: ('#' + Math.floor(Math.random() * 10000000 + 1)).slice(
									0,
									7
								),
							};
						}

						// time distribution for age distribution.
						var ele = [new Date(elem.date).getTime(), elem.distribution[cat]];

						if (cat === '15-19') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									fifteen: [...prevState.age_data.fifteen, ele],
								},
							}));
						} else if (cat === '20-24') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									twenty: [...prevState.age_data.twenty, ele],
								},
							}));
						} else if (cat === '25-29') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									twenty_five: [...prevState.age_data.twenty_five, ele],
								},
							}));
						} else if (cat === '30-34') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									thirty: [...prevState.age_data.thirty, ele],
								},
							}));
						} else if (cat === '35-39') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									thirty_five: [...prevState.age_data.thirty_five, ele],
								},
							}));
						} else if (cat === '40-44') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									forty: [...prevState.age_data.forty, ele],
								},
							}));
						} else if (cat === '45-49') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									forty_five: [...prevState.age_data.forty_five, ele],
								},
							}));
						} else if (cat === '50-54') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									fifty: [...prevState.age_data.fifty, ele],
								},
							}));
						} else if (cat === '55-59') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									fifty_five: [...prevState.age_data.fifty_five, ele],
								},
							}));
						} else if (cat === '60-64') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									sixty: [...prevState.age_data.sixty, ele],
								},
							}));
						} else if (cat === '65-69') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									sixty_five: [...prevState.age_data.sixty_five, ele],
								},
							}));
						} else if (cat === '70-74') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									seventy: [...prevState.age_data.seventy, ele],
								},
							}));
						} else if (cat === '75-79') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									seventy_five: [...prevState.age_data.seventy_five, ele],
								},
							}));
						} else if (cat === '80-84') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									eighty: [...prevState.age_data.eighty, ele],
								},
							}));
						} else if (cat === '85-89') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									eighty_five: [...prevState.age_data.eighty_five, ele],
								},
							}));
						} else if (cat === '90-94') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									ninety: [...prevState.age_data.ninety, ele],
								},
							}));
						} else if (cat === '95-99') {
							self.setState((prevState) => ({
								age_data: {
									...prevState.age_data,
									ninety_five: [...prevState.age_data.ninety_five, ele],
								},
							}));
						}
					});
				}

				// sort keys
				var sorted = Object.keys(series_data).sort(function (a, b) {
					return a.split('-')[0] - b.split('-')[0];
				});

				// sort data according to keys
				var sorted_series_data = [];
				for (const key of sorted) {
					sorted_series_data[key] = series_data[key];
				}

				this.setState({
					series_data: sorted_series_data,
					loading: false,
				});

				/* console.log(this.state); */
			})
			.catch((error) => {
				console.log('ERROR ', error);
			});
	}

	show() {
		this.setState({ IsModalOn: true, visible: true });
	}

	hide() {
		this.setState({ IsModalOn: false, visible: false });
	}

	render() {
		this.options = {
			chart: {
				/* width: 600,
					
					height: (4 / 6) * 100 + "%"*/
				height: 260,
			},
			title: {
				useHTML: true,
				text: '<div>Age Distribution</div>',
				align: 'left',
				y: 4,
				x: 0,
				style: {
					color: 'rgb(16, 16, 122)',
					font: 'bold 16px "Nunito" Arial, Helvetica sans-serif;',
				},
			},
			credits: {
				enabled: false,
			},
			xAxis: {
				categories: Object.keys(this.state.series_data),
				crosshair: true,
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Walkins/day',
				},
			},
			tooltip: {
				headerFormat:
					'<tr><td style="color:{series.color};padding:0"></td></tr>',
				footerFormat: '</table>',
				shared: true,
				useHTML: true,
			},
			plotOptions: {
				series: {
					groupPadding: 0,
				},
				column: {
					pointPadding: 0.2,
					borderWidth: 0,
				},
			},
			series: [
				{
					name: 'Walkins',
					showInLegend: false,
					data: Object.values(this.state.series_data),
					type: 'column',
				},
			],
			responsive: {
				rules: {
					condition: {
						minWidth: 100,
						callback() {
							return true;
						},
					},
					chartOptions: {
						legend: { enabled: false },
						yAxis: { title: { text: '' } },
					},
				},
			},
		};

		return (
			<div>
				<div className='age' onClick={this.show.bind(this)}>
					{this.state.loading ? (
						<Loader />
					) : (
						<HighchartsReact highcharts={Highcharts} options={this.options} />
					)}
				</div>
				{this.state.IsModalOn ? (
					<Rodal
						customStyles={{
							height: '60%',
							width: '50%',
							bottom: 'auto' /* ,
						top: '20%' */,
						}}
						visible={this.state.visible}
						onClose={this.hide.bind(this)}
					>
						<div style={{ width: '95%', textAlign: 'center' }}>
							<AgeTimeChart range={this.range} ageData={this.state.age_data} />
						</div>
					</Rodal>
				) : (
					''
				)}
			</div>
		);
	}
}

export default Age;
