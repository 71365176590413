import React from "react";
import mailImg from "./../img/mail.png";
import heartImg from "./../img/heart.png";
import lineImg from "./../img/lineChart.png";
//import line from "./../img/line.png";
import "./recentexperience.css";

function RecentExperience() {
	return (
		<div className='recentExperience'>
			<h3>Triggered Experience</h3>
			<h4>Recent Experience</h4>
			<div className='recent'>
				
					
					<span>
					<span className='iconimg'><img className='iconImg' src={mailImg} alt='emailimg' /></span>
						<span>Email Creditcard Document</span>
						<p>Opened</p>
					</span>
				
					<span>
						<span>
						<img className='iconImg' src={lineImg} alt='DMPeriod' />
						</span>	
							<span>DM Periodic Invest Plan</span>
						<p>sent</p>
					</span>
			

				<span>
					<span><img className='iconImg' src={heartImg} alt='heartimg' /></span>
					<span>
						<span>Email birthday wishes</span>
						<p>sceduled</p>
					</span>
				</span>
					
				
				{/*  <NavLink to='/'> */}
				<button className='submit'>View all</button>
				{/*   </NavLink> */}
			</div>
		</div>
	);
}
export default RecentExperience;
