import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

class AgeTimeChart extends React.Component {
	render() {
		this.range = this.props.range;
		//console.log("PROP AGE ",this.props.ageData)
		this.options = {
			chart: {
				zoomType: 'xy',
				height: 400
				/* Width: 100 + '%' */
				/*  height: (8 / 13) * 100 + "%" */ // 16:9 ratio
				/* height: (1.5 / 6) * 100 + "%" */
				/* height: (9 / 16) * 100 + "%" */
			},
			title: {
				text: 'Time Distribution'
			},
			subtitle: {
				text: ' for Age Distribution'
			},
			xAxis: {
				type: 'datetime',
				dateTimeLabelFormats: {
					/* week: '%e of %b' */
				},
				/* tickInterval: 3600000,// Hourly */
				min: new Date(this.range[0]).getTime(),
				// min: new Date(this.range[0]).getTime(), //this.range[0],
				max: new Date(this.range[1]).getTime(),
				crosshair: true
			},
			yAxis: [
				{
					// Primary yAxis
					labels: {
						format: '{value}',
						style: {
							color: Highcharts.getOptions().colors[2]
						}
					},
					title: {
						text: 'Walkins/day',
						style: {
							color: Highcharts.getOptions().colors[0]
						}
					},
					opposite: true
				}
			],
			tooltip: {
				shared: true
			},
			series: [
				{
					name: '15-19',
					type: 'line',
					data: this.props.ageData.fifteen,
					tooltip: {
						valueSuffix: ''
					},
					pointInterval: 3600000 // one hour
				},
				{
					name: '20-24',
					type: 'line',
					data: this.props.ageData.twenty,
					pointStart: (function() {
						let yesterday = new Date() - 86400000; //todays date - milliseconds per day
						return yesterday;
					})(),

					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '25-29',
					type: 'line',
					data: this.props.ageData.twenty_five,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},

					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},

				{
					name: '30-34',
					color: '#397C8A',
					type: 'line',
					data: this.props.ageData.thirty,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					tooltip: {
						valueSuffix: ' '
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '35-39',
					type: 'line',
					color: '#F0E539',
					data: this.props.ageData.thirty_five,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '40-44',
					type: 'line',
					color: '#F07E39',
					data: this.props.ageData.forty,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '45-49',
					type: 'line',
					color: '#EAB6DE',
					data: this.props.ageData.forty_five,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '50-54',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.fifty,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '55-59',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.fifty_five,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '60-64',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.sixty,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '65-69',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.sixty_five,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '70-74',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.seventy,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '75-79',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.seventy_five,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '80-84',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.eighty,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '85-89',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.eighty_five,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '90-94',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.ninety,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: '95-99',
					type: 'line',
					color: '#980045',
					data: this.props.ageData.ninety_five,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				}
			],
			credits: {
				enabled: false
			},
			legend: {
				enabled: false,
				layout: 'horizontal',
				align: 'middle',
				verticalAlign: 'bottom'
			},

			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 400,
							maxHeight: 400
						},
						chartOptions: {
							chart: {
								height: 300
							},
							subtitle: {
								text: null
							},
							navigator: {
								enabled: false
							},
							showLastLabel: false
						}
					}
				]
			}
		};
		return (
			<HighchartsReact
				highcharts={Highcharts}
				constructorType={'stockChart'}
				options={this.options}
			/>
		);
	}
}

export default AgeTimeChart;
