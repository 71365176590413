import React from "react";
import { NavLink } from "react-router-dom";
import line from "./../img/Linelogin.png";
import logo from "./../img/loginlogo.png";

import { Message } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { addAuthToken } from '../redux/actions';

import "./loginForm.css";

class NameForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = { 
			username: "",
			password: "",
			isValidUsername : true,
			isValidPassword : true,
			isWrongCred: false,
		};


		this.handleUsername = this.handleUsername.bind(this);
		this.handlePassword = this.handlePassword.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleUsername(event) {
		this.setState({ username: event.target.value });
		this.setState({isValidUsername: true})
	}

	handlePassword(event) {
		this.setState({ password: event.target.value });
		this.setState({isValidPassword: true})
	}

	handleSubmit(event) {

		if(this.state.username === ""){
			this.setState({isValidUsername: false});
			return false;
		}
		if(this.state.password === ""){
			this.setState({isValidPassword: false});
			return false;
		}
			
		const loginURL = "https://api.cyrix.io:8443/facade/authenticate";
		const options = {
			method : 'POST',
			mode: 'cors',
			headers: new Headers({
				"Content-Type": 'application/json',
			}),
			body: JSON.stringify(this.state),
		};

		fetch(loginURL, options)
			.then((data) => data.json() )
			.then((data) => {
				sessionStorage.setItem('authToken', data.jwt);
				this.props.dispatch(addAuthToken(data.jwt));
				this.props.history.push("/Dashboard");
			})
			.catch((error)=>{
				console.log("ERROR ", error);
				this.setState({isWrongCred:true});
			})

		event.preventDefault();
	}

	render() {
		return (
			<div className='login-container'>
				<div className='center'>
					<img src={logo} alt='CyrixLogo' />
					<span className='login-title'>Cyrix Experience Platform</span>
				</div>
				<form onSubmit={this.handleSubmit} className='form-login'>
					{/* 	<p className='text-muted'>Cyrix Experience Platform</p> */}
					<p className='loginP'>Login to Cyrix cloud platform</p>
					<div className='inputGroup'>
						<input 
							type='username'
							placeholder='Login'
							value={this.state.username}
							onChange={this.handleUsername}
						/>
						{!this.state.isValidUsername && <Message style={{color:'red'}} negative color='red'>
							Username is required!
						</Message> }

						<input 
							type='password' 
							placeholder='Password' 
							value={this.state.password}
							onChange={this.handlePassword}
						/>
						{ !this.state.isValidPassword && <Message style={{color:'red'}} negative color='red'>
							Password is required!
						</Message> }
						{/* <NavLink to='/DashBoard'> */}
							<button className='loginButton'>Log in</button>
						{/* </NavLink> */}
						{this.state.isWrongCred && <Message style={{color:'red'}} negative color='red'>
							You entered wrong credentials!
						</Message> }
						<img src={line} alt='line' className='imgLine' />
						<p>
							Not a user yet? <NavLink to='/Register'>Create account</NavLink>
						</p>
					</div>
				</form>
				<p className='terms-login'>
					<NavLink to="">Privacy policy</NavLink> &nbsp; &nbsp;
					<NavLink to="">Terms of use</NavLink>
				</p>
			</div>
		);
	}
}

export default connect()( NameForm );
