import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import configStore from './redux/store';
import App from './components/App';
/* import * as serviceWorker from './serviceWorker'; */

const { store, persistor } = configStore();

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={"Processing"} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
	,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/* serviceWorker.unregister(); */
