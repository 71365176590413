import React from 'react';
import './header.css';
import logo from './../img/logo.png';
import picture from './../img/loginpic1.png';

function Header(props) {
	return (
		<div className='header row'>
			<section className='header-left col-lg-10 col-md-8'>
				<img className='logo' src={logo} alt='logo' />
				<span className='header-store'>
					{props.heading}
					<span className='header-sub-date'>
						{new Date().getDate()}/{new Date().getMonth() + 1}/
						{new Date().getFullYear()}
					</span>
				</span>
			</section>
			<div className='header-right col-lg-2 col-md-4 '>
				<div className='header-name-field float-right'>
					<span className='login-pic'>
						<img src={picture} alt='login-pic' />
					</span>
					<div className='login-name-wrapper'>
						<span className='login-designation'>Sales Manager</span>
						<span className='login-title-name'>Henrik Larsen</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Header;
