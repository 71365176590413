import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

class FrequencyTimeChart extends React.Component {

	render(){
	
		this.options = {
			chart: {
				height: 400
			},

			rangeSelector: {
				allButtonsEnabled: true,
				buttonTheme: {
					width: 60
				},
				selected: 2
			},
			xAxis: {
				min: Date.UTC(2019),
				max: new Date().getTime() //get actual time
			},
			credits: {
				enabled: false
			},
			title: {
				text: 'Time Distribution'
			},

			subtitle: {
				text: ' for Frequency Distribution'
			},

			_navigator: {
				enabled: true
			},

			series: [
				{
					type: 'column',
					name: 'custumers',
					data: this.props.walkins,
					marker: {
						enabled: null, // auto
						radius: 3,
						lineWidth: 1,
						lineColor: '#FFFFFF'
					},
					tooltip: {
						valueDecimals: 2
					}
				}
			]
		};
	
		return (
			<div>
				<HighchartsReact
					highcharts={Highcharts}
					constructorType={'stockChart'}
					options={this.options}
				/>
			</div>
		);
	}
}

export default FrequencyTimeChart;
