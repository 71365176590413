import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import './slider.css';

class SliderInput extends React.Component {
	state = {
		startDate: this.props.range[0],
		endDate: this.props.range[1],
	};
	/* 	componentWillReceiveProps({ range }) {
		this.setState({ ...this.state, range });
	}
	shouldComponentUpdate(nextProps) {
		const rangeUpdated = this.props.range !== nextProps.range;
		return rangeUpdated;
	} */

	render() {
		/* 	console.log('propssssssss range in input slider', this.props.range);
		console.log(this.state.startDate, 'strt date in input'); */
		return (
			<div className='col-md-12 sliderInput flex'>
				<label>From </label>
				<DatePicker
					selected={this.state.startDate}
					selectsStart
					value={this.state.startDate}
					onChange={(date) => {
						this.setState({
							startDate: date,
						});
						this.props.action([date, this.state.endDate]);
					}}
					showTimeSelect
					timeFormat='HH:mm'
					timeIntervals={30}
					timeCaption='time'
					dateFormat='MMMM d, yyyy h:mm aa'
					startDate={this.state.startDate}
					endDate={this.state.endDate}
					popperModifiers={{
						offset: {
							enabled: true,
							offset: '-40px,10px',
						},
						preventOverflow: {
							enabled: true,
							escapeWithReference: false,
							boundariesElement: 'viewport',
						},
					}}
				/>

				<label>To </label>
				<DatePicker
					selected={this.state.endDate}
					selectsEnd
					value={this.state.endDate}
					onChange={(date) => {
						this.setState({
							endDate: date,
						});
						this.props.action([this.state.startDate, date]);
					}}
					showTimeSelect
					timeFormat='HH:mm'
					timeIntervals={30}
					timeCaption='time'
					dateFormat='MMMM d, yyyy h:mm aa'
					startDate={this.state.startDate}
					endDate={this.state.endDate}
					popperModifiers={{
						offset: {
							enabled: true,
							offset: '-40px,10px',
						},
						preventOverflow: {
							enabled: true,
							escapeWithReference: false,
							boundariesElement: 'viewport',
						},
					}}
				/>
			</div>
		);
	}
}

export default SliderInput;
/* <div className='sliderInput flex'>
							<label>From </label>
							<DatePicker
								selected={this.state.startDate}
								selectsStart
								onChange={date =>
									this.setState({ startDate: date, isInputDateUpdated: true })
								}
								showTimeSelect
								timeFormat='HH:mm'
								timeIntervals={30}
								timeCaption='time'
								dateFormat='MMMM d, yyyy h:mm aa'
								startDate={this.state.startDate}
								endDate={this.state.endDate}
							/>
							<label>To </label>
							<DatePicker
								selected={this.state.endDate}
								selectsEnd
								onChange={date =>
									this.setState({ endDate: date, isInputDateUpdated: true })
								}
								showTimeSelect
								timeFormat='HH:mm'
								timeIntervals={30}
								timeCaption='time'
								dateFormat='MMMM d, yyyy h:mm aa'
								startDate={this.state.startDate}
								endDate={this.state.endDate}
								minDate={this.state.startDate}
							/>
						</div> */
