import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { format } from 'date-fns';
import Loader from './../loader';
import configStore from '../../redux/store';

/* import "./timechart.css"; */
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

/* const ReactHighcharts = require("react-highcharts"); */

/* const { store } = configStore(); */

class TimeChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			series_data: []
		};
		// this.range = JSON.parse(store.getState().slider.range);
		this.getTimeDistribution();
	}

	getTimeDistribution() {
		this.setState({ loading: true });
		// this.range = JSON.parse(store.getState().slider.range);
		this.range = JSON.parse(sessionStorage.getItem('range'));
		const freqURL =
			'https://api.cyrix.io:8443/facade/rest/frequencyDistribution/filter/';
		const options = {
			method: 'POST',
			mode: 'cors',
			headers: new Headers({
				'Content-Type': 'application/json',
				// 'Authorization' : 'Bearer '+ store.getState().login.token,
				Authorization: 'Bearer ' + sessionStorage.getItem('authToken')
			}),
			body: JSON.stringify({
				camera: 1,
				from: format(new Date(this.range[0]), 'yyyy-MM-dd'),
				to: format(new Date(this.range[1]), 'yyyy-MM-dd')
			})
		};

		fetch(freqURL, options)
			.then(data => data.json())
			.then(data => {
				var walkins = [];
				for (const elem of data) {
					walkins = [
						...walkins,
						[
							new Date(elem.date).getTime(),
							elem.newCustomers + elem.recurringCustomers
						]
					];
				}
				this.setState(prevState => ({
					// series_data : [...prevState.series_data, walkins]
					series_data: walkins,
					loading: false
				}));
				/* console.log('TIME Distribution ', this.state.series_data); */
			})
			.catch(error => {
				console.log('ERROR ', error);
			});
	}

	render() {
		this.options = [
			{
				chart: {
					height: 220
				},
				title: {
					useHTML: true,
					text: '<div>Time Distribution</div>',
					align: 'left',
					style: {
						color: 'rgb(16, 16, 122)',
						font: 'bold 16px "Nunito" Arial, Helvetica sans-serif;'
					}
				},
				xAxis: {
					type: 'datetime',
					/* min: new Date(), */
					tickInterval: 3600000,
					dateTimeLabelFormats: {
						hour: '%H:%M'
					},
					min: new Date(this.range[0]).getTime(),
					max: new Date(this.range[1]).getTime()
				},
				series: [
					{
						type: 'spline',
						name: 'Walkins/day',
						data: this.state.series_data,
						pointStart: new Date(this.range[0]).getTime(), //new Date('2019-10-01').getTime(),
						pointInterval: 3600000 // one hour
					}
				],
				yAxis: {
					title: {
						text: 'Walkins'
					},
					min: 0
				},

				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						}
					}
				},
				credits: {
					enabled: false
				},
				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 500
							},
							chartOptions: {
								legend: {
									layout: 'horizontal',
									align: 'center',
									verticalAlign: 'bottom'
								}
							}
						}
					]
				}
			}
		];

		return (
			<div className='time'>
				{this.state.loading ? (
					<Loader />
				) : (
					this.options.map(chart => (
						<HighchartsReact
							key={new Date()}
							highcharts={Highcharts}
							options={chart}
						/>
					))
				)}
			</div>
		);
	}
}

export default TimeChart;
