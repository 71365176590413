import React from 'react';
import Frequency from './frequency/frequency';
import Age from './age/age';
import TimeChart from './timechart';
import Gender from './gender/gender';
import EmotionChart from './emotionChart';
import KPIs from './kpis';
import Slider from '../slider/slider';
import Loader from './../loader';

import 'react-datepicker/dist/react-datepicker.css';

/* css in dashboard.css */
const kpiProps = [
	{
		entrence: 'Sales customer',
		kpi: Math.floor(Math.random() * Math.floor(40)),
	},
	{
		entrence: 'Workshop customer',
		kpi: Math.floor(Math.random() * Math.floor(35)),
	},
];

class Storetraffic extends React.Component {
	state = {
		loading: true,
		startDate: new Date(),
		endDate: new Date(),
		isInputDateUpdated: false,
	};

	// constructor(props){
	// 	super(props);
	// 	this.gender = React.createRef();
	// 	console.log(this.gender);
	// 	this.frequency = React.createRef();
	// 	this.emotion = React.createRef();
	// 	this.age = React.createRef();
	// 	this.time = React.createRef();
	// }

	callChildFunctions = () => {
		console.log('childFunc Called ');
		// console.log(this.gender);
		if (this.gender !== undefined) this.gender.getGenderDist();
		if (this.frequency !== undefined) this.frequency.getFrequencyDist();
		if (this.emotion !== undefined) this.emotion.getEmotionDist();
		if (this.age !== undefined) this.age.getAgeDistribution();
		if (this.time !== undefined) this.time.getTimeDistribution();
	};

	/* 	handleOnChangeDate(date){
		this.setState({ loading: true });
		this.callChildFunctions(date);
		this.setState({ loading: false });
	} */

	componentDidMount() {
		this.callChildFunctions();
		this.setState({ loading: false, isInputDateUpdated: false });
	}
	/* 
	 componentWillUpdate(prevProps, prevState) {
		if (
			prevState.startDate !== this.state.startDate ||
			prevState.endDate !== this.state.endDate
		)
			console.log('date inputs changed');
		this.state.startDate = Date.parse(this.state.startDate);
		this.state.endDate = Date.parse(this.state.endDate);
		console.log('start:::', this.state.startDate);
		console.log('end:::', this.state.endDate);
		
		console.log(this.state.isInputDateUpdated, '::::::isInputDateUpdated');
		
	} */

	render() {
		/* console.log(this.state.startDate, '==========', this.state.endDate); */
		return this.state.loading ? (
			<Loader />
		) : (
			<section className='main flex container-fluid'>
				<section className='gfaca flex col-lg-12'>
					<div className='rangeslider row'>
						<div className='col-md-12 col-lg-12'>
							{this.state.isInputDateUpdated ? (
								<Slider
									childFunc={this.callChildFunctions.bind(this)}
									startDate={this.state.startDate}
									endDate={this.state.endDate}
								/>
							) : (
								<Slider childFunc={this.callChildFunctions.bind(this)} />
							)}
						</div>
					</div>
				</section>

				{/* <Navigation />  */}
				{/* g-gender,f-frequency,a-activity,c-customers,a-age */}
				<section className='gfaca flex col-lg-8 col-md-12'>
					{/* gf and ac ,g-gender,f-frequency,a-activity,c-customers */}
					<div className='row'>
						<div className='col-md-12'>
							<section className='gfandac flex row'>
								<div className='col-md-6'>
									<section className='gf flex '>
										<Gender
											ref={(gender) => {
												this.gender = gender;
											}}
										/>
										<Frequency
											ref={(frequency) => {
												this.frequency = frequency;
											}}
										/>
									</section>
								</div>
								<div className='col-md-6'>
									<section className='ac flex'>
										{/* 	<ActivityMap />
									<Customers /> */}
										<EmotionChart
											ref={(emotion) => {
												this.emotion = emotion;
											}}
										/>
									</section>
								</div>
							</section>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12'>
							<Age
								ref={(age) => {
									this.age = age;
								}}
							/>
						</div>
					</div>
				</section>
				<div className='col-lg-4 col-md-12 time'>
					<section>
						<TimeChart
							ref={(time) => {
								this.time = time;
							}}
						/>
					</section>
					{kpiProps.map((prop) => {
						return (
							<section
								key={prop.kpi}
								className='kpiTime'
								style={{ backgroundColor: 'White', height: 210 }}
							>
								<KPIs title={prop.entrence} kpi={prop.kpi} />
							</section>
						);
					})}
				</div>
			</section>
		);
	}
}

export default Storetraffic;
