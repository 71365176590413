import React from "react";
import Header from "./header";
import Navigation from "./navigation";
import Settingform from "./settingform";
import "./settingform.css";

function UserSettings() {
	return (
		<div className='usersettings-container'>
			<p className='text'>Cyrix Experience Platform</p>
			<Header heading='User settings' />
			<section className='flex'>
				<Navigation />
				<Settingform />
				{/* in UserSettings component */}
			</section>
		</div>
	);
}
export default UserSettings;
