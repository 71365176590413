import React, { Component } from 'react';
import { Slider, Rail, Tracks, Handles } from 'react-compound-slider';
import { SliderRail, Track, Handle } from './slidermethods';
import { subDays, startOfToday, format } from 'date-fns';

import './slider.css';

import { connect } from 'react-redux';
import { changeDateRange } from '../../redux/actions';
import { subHours } from 'date-fns/esm';
import SliderInput from './sliderInput';

const { zonedTimeToUtc, utcToZonedTime } = require('date-fns-timezone');

const sliderStyle = {
	position: 'relative',
	width: '100%',
};

const halfHour = 1000 * 60 * 30;

class RangeSlider extends Component {
	constructor(props) {
		super(props);
		/* const domain = [subdays(today,30), today]; */

		/* const today = startOfToday(); */
		const now = new Date();
		const oneHourAgo = subHours(now, 1);
		const fourDaysAgo = subDays(now, 8);
		const oneMonthAgo = subDays(now, 30);
		this.state = {
			range: [oneHourAgo, now],
			/* range: [fourDaysAgo, now], */
			selected1: fourDaysAgo,
			selected2: now,
			min: oneMonthAgo,
			max: now,
		};
		/* console.log(this.state.range); */
		this.onChangeHandler = this.onChangeHandler.bind(this);
		sessionStorage.setItem('range', JSON.stringify(this.state.range));
		props.dispatch(changeDateRange(JSON.stringify(this.state.range)));
	}

	// componentDidUpdate(prevProps){
	// 	console.log(JSON.parse(store.getState().slider.range)[0]);
	// 	console.log(this.state.range[0]);
	//     if(JSON.parse(store.getState().slider.range)[0] !== this.state.range[0])
	//    	{
	//    		console.log("DATE CHNGED ")
	//    		this.props.childFunc()
	//    	}
	//  	}

	onChangeHandler = (range) => {
		/* console.log('slider onchange', range); */
		this.setState(
			{
				range,
			},
			() => {
				sessionStorage.setItem('range', JSON.stringify(this.state.range));
				this.props.dispatch(changeDateRange(JSON.stringify(range)));
				/* console.log(
					format(new Date(range[0]), 'MMM dd h:mm a'),
					format(new Date(range[1]), 'MMM dd h:mm a')
				); */

				this.props.childFunc();
			}
		);
	};

	render() {
		const { min, max, range } = this.state;

		return (
			<div>
				<div className='slider-name'>
					{/* 	{console.log(
						format(new Date(range[0]), 'MMM dd h:mm a'),
						'-----',
						format(new Date(range[1]), 'MMM dd h:mm a')
					)} */}
					Date / Time :{format(new Date(range[0]), 'MMM dd h:mm a')} <b>-</b>
					{format(new Date(range[1]), 'MMM dd h:mm a')}
				</div>
				<div className='flex sliderrow row'>
					<div style={{ alignSelf: 'center' }} className='col-mg-11 col-lg-7'>
						<Slider
							mode={1}
							step={halfHour}
							domain={[+min, +max]}
							rootStyle={sliderStyle}
							onChange={this.onChangeHandler}
							onUpdate={this.onUpdate}
							values={range}
						>
							{
								<Rail>
									{({ getRailProps }) => (
										<SliderRail getRailProps={getRailProps} />
									)}
								</Rail>
							}
							<Handles>
								{({ handles, getHandleProps }) => (
									<div className='slider-handles'>
										{handles.map((handle) => (
											<Handle
												key={handle.id}
												handle={handle}
												domain={[+min, +max]}
												getHandleProps={getHandleProps}
											/>
										))}
									</div>
								)}
							</Handles>

							<Tracks left={false} right={false}>
								{({ tracks, getTrackProps }) => (
									<div className='slider-tracks'>
										{tracks.map(({ id, source, target }) => (
											<Track
												key={id}
												source={source}
												target={target}
												getTrackProps={getTrackProps}
											/>
										))}
									</div>
								)}
							</Tracks>
						</Slider>
					</div>
					<section className='sliderinputShowHide col-auto'>
						<SliderInput
							action={this.onChangeHandler}
							range={this.state.range}
						/>
					</section>
				</div>
			</div>
		);
	}
}

export default connect()(RangeSlider);
