import React from "react";
import Header from "./header";
import Navigation from "./navigation";
import "./notification.css";
function Notification() {
	return (
		<div className='notification-container'>
			<p className='text'>Cyrix Experience Platform</p>
			<Header heading='Notification center' />
			<section className='flex'>
				<Navigation />
				<section className='notification'>
				<div class="table-responsive-sm">
  					<table class="table table-hover">
						<thead>
							<tr className='notification-row'>
								<th>Date</th>
								<th>Time</th>
								<th>Location</th>
								<th>Camera</th>
								<th class-name='notify-desc'>Description</th>
								<th>Received by</th>
							</tr>
						</thead>
						<tbody>
							<tr className='notification-row'>
								<td>22/09/2019</td>
								<td>13:12:33</td>
								<td>Amager</td>
								<td>3</td>
								<td class-name='notify-desc'>notified to service</td>
								<td>Larsen</td>
							</tr>
							<tr className='notification-row'>
								<td>22/09/2019</td>
								<td>13:12:33</td>
								<td>Amager</td>
								<td>3</td>
								<td className='notify-desc'>notified to reception</td>
								<td>Henrik</td>
							</tr>
							<tr className='notification-row'>
								<td>22/09/2019</td>
								<td>13:12:33</td>
								<td>Amager</td>
								<td>3</td>
								<td class-name='notify-desc'>notified to admin</td>
								<td>Henrik</td>
							</tr>
							<tr className='notification-row'>
								<td>22/09/2019</td>
								<td>13:12:33</td>
								<td>Amager</td>
								<td>3</td>
								<td class-name='notify-desc'>notified to service</td>
								<td>Dan</td>
							</tr>
							<tr className='notification-row'>
								<td>22/09/2019</td>
								<td>13:12:33</td>
								<td>Amager</td>
								<td>3</td>
								<td class-name='notify-desc'>notified to reception</td>
								<td>Lora</td>
							</tr>
						</tbody>
					</table>
				</div>	
					<div className='navlink-btns'>
						{/* 	<NavLink to='/'> */}
						<button className='submit'>Save</button>
						{/* 	</NavLink> */}
						{/* 	<NavLink to='/'> */}
						<button className='submit'>Export</button>
						{/* 	</NavLink> */}
					</div>
				</section>
			</section>
		</div>
	);
}
export default Notification;
