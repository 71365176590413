import { ADD_AUTH_TOKEN, CHANGE_DATE_RANGE } from './actionTypes';

export const addAuthToken = token => ({
	type: ADD_AUTH_TOKEN,
	payload: {token: token}
});

export const changeDateRange = range => ({
	type: CHANGE_DATE_RANGE,
	payload: {range: range}
});

