import React from "react";
/* import { NavLink } from "react-router-dom"; */

import image from "./../img/profile.png";
import carimg from "./../img/car.png";
import line from "./../img/line.png";
import "./personDetails.css";

function PersonDetails() {
	return (
		<div className='personalDetails'>
			<h3>Custumer Name</h3>
			<h4>Country</h4>
			<div className='flex'>
				<img
					className='profilePic'
					src={image}
					alt='personimage'
					width='100px'
				/>
				<section className='details'>
					<span>
						<h5>Person Age</h5>
						<p>Age</p>
						<img className='line' src={line} alt='line' />
					</span>
					<span>
						<h5>Brand Preferences</h5>
						<p>Audi/BMW/VolksWagen</p>
						<img className='line' src={line} alt='line' />
					</span>
					<span>
						<h5>Car type Preference</h5>
						<span className='flex carSpan'>
							<img className='carImg' src={carimg} alt='carimg' />
							<p> Saden</p>
						</span>
					</span>
					{/* 	<NavLink to='/'> */}
					<button className='submit'>View all</button>
					{/* 	</NavLink> */}
				</section>
			</div>
		</div>
	);
}
export default PersonDetails;
