import React from 'react';

function Loader() {

  return (
    <div className="loader center" style={{color:'#aaaaaa'}}>
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
    </div>
  );
}

export default Loader;
