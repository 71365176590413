import React from "react";
import MainRouter from "./router";
/* import Header from "./header";
import Navigation from "./navigation"; */

function App() {
	return <MainRouter />;
	/* (
		<div>
			<p className='text'>Cyrix Experience Platform</p>
			<Header />
			<section className='flex'>
				<Navigation />
				<MainRouter />
			</section>
		</div>
	); */
}

export default App;
