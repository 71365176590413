import React from 'react';
import Rodal from 'rodal';

import { format } from 'date-fns';
/* import configStore from '../../../redux/store'; */

// include styles
import 'rodal/lib/rodal.css';
import FrequencyTimeChart from './frequencyTime';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Loader from './../../loader';

import './../age/ageRodal.css';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
/* import "./frequency.css"; */

/* const { store } = configStore(); */

class Frequency extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			IsModalOn: false,
		};
		// this.range = JSON.parse(store.getState().slider.range);
		this.getFrequencyDist();
	}

	getFrequencyDist() {
		this.setState({ loading: true });
		// this.range = JSON.parse(store.getState().slider.range);
		this.range = JSON.parse(sessionStorage.getItem('range'));
		const freqURL =
			'https://api.cyrix.io:8443/facade/rest/frequencyDistribution/filter/';
		const options = {
			method: 'POST',
			mode: 'cors',
			headers: new Headers({
				'Content-Type': 'application/json',
				// 'Authorization' : 'Bearer '+ store.getState().login.token,
				Authorization: 'Bearer ' + sessionStorage.getItem('authToken'),
			}),
			body: JSON.stringify({
				camera: 1,
				from: format(new Date(this.range[0]), 'yyyy-MM-dd'),
				to: format(new Date(this.range[1]), 'yyyy-MM-dd'),
			}),
		};

		fetch(freqURL, options)
			.then((data) => data.json())
			.then((data) => {
				var walkins = [];
				var newCustomers = 0;
				var recurringCustomers = 0;

				for (const elem of data) {
					walkins = [
						...walkins,
						[
							new Date(elem.date).getTime(),
							elem.newCustomers + elem.recurringCustomers,
						],
					];
					newCustomers += elem.newCustomers;
					recurringCustomers += elem.recurringCustomers;
				}

				this.setState((prevState) => ({
					walkins: walkins,
					summary: [
						['Already visited', recurringCustomers],
						['First visit', newCustomers],
					],
					loading: false,
				}));
			})
			.catch((error) => {
				console.log('ERROR ', error);
			});
	}

	show() {
		this.setState({ IsModalOn: true, visible: true });
	}

	hide() {
		this.setState({ IsModalOn: false, visible: false });
	}

	render() {
		this.options = {
			chart: {
				type: 'pie',
				margin: [0, 0, 0, 15],
				spacingTop: 0,
				spacingBottom: 0,
				spacingLeft: 0,
				spacingRight: 0,

				// Explicitly tell the width and height of a chart
				/*width: 400,
					 height: 200 
					height: (1.3 / 3) * 100 + "%"*/
				height: 175,
				events: {
					load: function (event) {
						var chart = this,
							points = chart.series[0].points,
							len = points.length,
							total = 0,
							i = 0;

						for (; i < len; i++) {
							total += points[i].y;
						}

						chart.setSubtitle({
							text: 'Total:' + total,
							align: 'center',
							verticalAlign: 'middle',
							y: 1,
							x: 5,
							style: {
								fontWeight: 'bold',
								fontSize: '12',
							},
						});
					},
				},
			},
			title: {
				useHTML: true,
				text: '<div padding=3px >Frequency Distribution</div>',
				align: 'left',
				/* y: 12,
				x: 6, */
				style: {
					color: 'rgb(16, 16, 122)',
					font: 'bold 16px "Nunito" Arial, Helvetica sans-serif;',
				},
			},
			credits: {
				enabled: false,
			},
			plotOptions: {
				pie: {
					size: '50%',
					innerSize: '80%',
					depth: '90%',
					/* startAngle: 50, */
					dataLabels: {
						enabled: true,
						verticalAlign: 'middle',
						overflow: true,
						crop: false,
						/* align: 'right', */
						format: '<br><b>{point.name}:</b>{point.percentage:.1f} % ',
						/* format:
							'<br><b>{point.name}</b>:{point.percentage:.1f} %<br>total: {point.total}'<br>value:{point.y} */
					},
				},
			},
			series: [
				{
					name: 'Frequency',
					data: this.state.summary,
				},
			],
			responsive: {
				rules: {
					condition: {
						minWidth: 100,
						callback() {
							return true;
						},
					},
					chartOptions: {
						legend: { enabled: false },
						yAxis: { title: { text: '' } },
					},
				},
			},
		};
		return (
			<div>
				<div className='frequency' onClick={this.show.bind(this)}>
					{this.state.loading ? (
						<Loader />
					) : (
						<HighchartsReact highcharts={Highcharts} options={this.options} />
					)}
				</div>
				{this.state.IsModalOn ? (
					<Rodal
						customStyles={{
							height: '60%',
							width: '50%',
							bottom: 'auto' /* ,
						top: '20%' */,
						}}
						visible={this.state.visible}
						onClose={this.hide.bind(this)}
					>
						<div style={{ width: '95%', textAlign: 'center' }}>
							<FrequencyTimeChart walkins={this.state.walkins} />
						</div>
					</Rodal>
				) : (
					''
				)}
			</div>
		);
	}
}

export default Frequency;
