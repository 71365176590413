import { CHANGE_DATE_RANGE } from '../actionTypes';


const init_state = {
	range : ""
}
export default function( state = init_state, action){
	if(action.type === CHANGE_DATE_RANGE){
		return {
			...state,
			range: action.payload.range
		}
	}
	else{
		return state
	}
}