import React, { useState } from 'react';
import Loader from './../loader';

function KPIs(props) {
	const [loading, setLoading] = useState(true);
	setTimeout(() => {
		setLoading(false);
	}, 2000);
	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<div>
					<section style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div
							style={{
								fontFamily:
									'"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
								fontSize: 18,
								whiteSpace: 'nowrap',
								marginLeft: 10,
								marginTop: 0,
								left: 10,
								top: -3,
								color: 'rgb(16, 16, 122)'
								/* color: 'rgb(16, 16, 122)',
					font: 'bold 16px "Nunito" Arial, Helvetica sans-serif',
					textAlign: 'left' */
							}}
						>
							{props.title}
							<sub>/Day</sub>
						</div>
						<i
							className='fas fa-bars'
							style={{
								color: 'rgb(102, 102, 102)',
								padding: '10px 0',
								width: '25px'
							}}
						></i>
					</section>
					<span
						style={{
							fontSize: 70,
							display: 'table',
							margin: 'auto'
						}}
					>
						{props.kpi}
					</span>
				</div>
			)}
		</div>
	);
}

export default KPIs;
