import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './emotionalchart.css';

import { format } from 'date-fns';

import Loader from './../loader';
import configStore from '../../redux/store'; 
/* exporting to excel functionality  */
/* https://www.highcharts.com/forum/viewtopic.php?t=442 */
/* import HC_exporting from 'highcharts/modules/exporting'; */

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

/* const { store } = configStore(); */

class EmotionChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			happy: [],
			excited: [],
			surpised: [],
			neutral: [],
			sad: [],
			angry: [],
			disgused: [],
			fearful: []
		};
		// this.range = JSON.parse(store.getState().slider.range);
		this.getEmotionDist();
	}

	getEmotionDist() {
		this.setState({ loading: true });
		// this.range = JSON.parse(store.getState().slider.range);
		this.range = JSON.parse(sessionStorage.getItem('range'));
		const emotionURL =
			'https://api.cyrix.io:8443/facade/rest/emotionDistribution/filter/';
		const options = {
			method: 'POST',
			mode: 'cors',
			headers: new Headers({
				'Content-Type': 'application/json',
				// "Authorization": "Bearer "+ store.getState().login.token,
				Authorization: 'Bearer ' + sessionStorage.getItem('authToken')
			}),
			body: JSON.stringify({
				site: 1,
				// from: format(new Date("2019-01-01"), "yyyy-MM-dd"),
				from: format(new Date(this.range[0]), 'yyyy-MM-dd'),
				to: format(new Date(this.range[1]), 'yyyy-MM-dd')
			})
		};

		fetch(emotionURL, options)
			.then(data => data.json())
			.then(data => {
				var self = this;
				data.forEach(function(emotions) {
					Object.keys(emotions).forEach(emotion => {
						var ele = [new Date(emotions.date).getTime(), emotions[emotion]];
						if (emotion === 'happy') {
							self.setState(prevState => ({
								happy: [...prevState.happy, ele].sort(function(a, b) {
									return a[0] - b[0];
								})
							}));
						} else if (emotion === 'neutral') {
							self.setState(prevState => ({
								neutral: [...prevState.neutral, ele].sort(function(a, b) {
									return a[0] - b[0];
								})
							}));
						} else if (emotion === 'surpised') {
							self.setState(prevState => ({
								surpised: [...prevState.surpised, ele].sort(function(a, b) {
									return a[0] - b[0];
								})
							}));
						} else if (emotion === 'sad') {
							self.setState(prevState => ({
								sad: [...prevState.sad, ele].sort(function(a, b) {
									return a[0] - b[0];
								})
							}));
						} else if (emotion === 'fearful') {
							self.setState(prevState => ({
								fearful: [...prevState.fearful, ele].sort(function(a, b) {
									return a[0] - b[0];
								})
							}));
						} else if (emotion === 'disgused') {
							self.setState(prevState => ({
								disgused: [...prevState.disgused, ele].sort(function(a, b) {
									return a[0] - b[0];
								})
							}));
						} else if (emotion === 'angry') {
							self.setState(prevState => ({
								angry: [...prevState.angry, ele].sort(function(a, b) {
									return a[0] - b[0];
								})
							}));
						}
					});
				});
				self.setState({ loading: false });
				// console.log("EMOTION DATA PARSED ", this.state);
			})
			.catch(error => {
				console.log('EMOTION ERROR ', error);
			});
	}

	render() {
		/*https://www.highcharts.com/demo/combo-multi-axes */
		this.options = {
			chart: {
				zoomType: 'xy',
				height: 380,
				Width: 100 + '%'
				/*  height: (8 / 13) * 100 + "%" */ // 16:9 ratio
				/* height: (1.5 / 6) * 100 + "%" */
				/* height: (9 / 16) * 100 + "%" */
			},
			title: {
				text: 'Emotional Mapping',
				align: 'left',
				style: {
					color: 'rgb(16, 16, 122)',
					font: 'bold 16px "Nunito" Arial, Helvetica sans-serif;'
				}
			},
			xAxis: {
				type: 'datetime',
				dateTimeLabelFormats: {
					week: '%e of %b'
				},
				tickInterval: 3600000,
				//min: new Date('2019-01-01').getTime(),
				min: new Date(this.range[0]).getTime(),
				max: new Date(this.range[1]).getTime(),
				crosshair: true
			},
			yAxis: [
				{
					// Primary yAxis
					labels: {
						format: '{value}',
						style: {
							color: Highcharts.getOptions().colors[2]
						}
					},
					title: {
						text: 'Emotions',
						style: {
							color: Highcharts.getOptions().colors[0]
						}
					},
					opposite: true
				}
			],
			tooltip: {
				shared: true
			},
			series: [
				{
					name: 'Happy',
					type: 'line',
					data: this.state.happy,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: 'Excited',
					type: 'line',
					data: this.state.excited,
					pointStart: (function() {
						let yesterday = new Date() - 86400000; //todays date - milliseconds per day
						return yesterday;
					})(),

					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: 'Surprise',
					type: 'line',
					data: this.state.surpised,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},

					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: 'Neutral',
					color: '#397C8A',
					type: 'line',
					data: this.state.neutral,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					tooltip: {
						valueSuffix: ' '
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: 'Sad',
					type: 'line',
					color: '#F0E539',
					data: this.state.sad,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				},
				{
					name: 'Angry',
					type: 'line',
					color: '#F07E39',
					data: this.state.angry,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				} /* ,
				{
					name: 'Disguised',
					type: 'line',
					color: '#EAB6DE',
					data: this.state.disgused,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //todays date - milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), 
					pointInterval: 3600000 // one hour
				}, */,
				{
					name: 'Fearful',
					type: 'line',
					color: '#980045',
					data: this.state.fearful,
					// pointStart: (function() {
					// 	let yesterday = new Date() - 86400000; //today's date minus milliseconds per day
					// 	return yesterday;
					// })(),

					marker: {
						enabled: false
					},
					dashStyle: 'shortdot',
					tooltip: {
						valueSuffix: ''
					},
					/* pointStart: Date.UTC(2019, 10, 19), */
					pointInterval: 3600000 // one hour
				}
			],
			credits: {
				enabled: false
			},
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 500
						},
						chartOptions: {
							yAxis: [
								{
									labels: {
										align: 'right',
										x: 0,
										y: -6
									},
									showLastLabel: false
								},
								{
									labels: {
										align: 'left',
										x: 0,
										y: -6
									},
									showLastLabel: false
								},
								{
									visible: false
								}
							]
						}
					}
				]
			}
		};
		return (
			<div className='emotionalchart'>
				{this.state.loading ? (
					<Loader />
				) : (
					<HighchartsReact highcharts={Highcharts} options={this.options} />
				)}
			</div>
		);
	}
}
export default EmotionChart;
