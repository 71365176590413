import React from "react";
import "./settingform.css";

function Settingform() {
	return (
		<form className='user-form'>
			<fieldset>
				<legend>Account</legend>
				<div className=' flex form-element'>
					<label for='name'>Name</label>
					<input type='text' id='name' placeholder='Per' />
				</div>
				<div className=' flex form-element'>
					<label for='email'>Email</label>
					<input type='email' id='email' placeholder='pa@mail.com' />
				</div>
				<div className=' flex form-element'>
					<label for='mobile'>Mobile</label>
					<input type='number' id='mobile' placeholder='34556735' />
				</div>
				<div className=' flex form-element'>
					<label for='phone'>Phone</label>
					<input type='number' id='phone' placeholder='34504835' />
				</div>
			</fieldset>
			<fieldset>
				<legend>Password</legend>
				<div className=' flex'>
					<ul>
						<li>Minimum 8 charecters.</li>
						<li>One special charecter.</li>
						<li>4 Unique charecters.</li>
					</ul>
					<div className='input-div  form-element'>
						<input type='password' placeholder='CurrentPassword' />
						<input type='password' placeholder='NewPassword' />
						<input type='password' placeholder='RepeatNewPassword' />
					</div>
				</div>
			</fieldset>
		</form>
	);
}
export default Settingform;
